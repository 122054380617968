/*
 * @Author: qiankun
 * @LastEditors: qiankun
 */
import * as svg from '@element-plus/icons-vue'
import ElSvgIcon from '@/components/ElSvgIcon.vue'
// svg-icon
//import svg-icon doc in  https://github.com/anncwb/vite-plugin-svg-icons/blob/main/README.zh_CN.md
import 'virtual:svg-icons-register'
import SvgIcon from '@/icon/SvgIcon.vue'

export function elSvg(app) {
  const icons = svg
  for (const i in icons) {
    app.component(`ele-${icons[i].name}`, icons[i])
  }
  app.component('ElSvgIcon', ElSvgIcon)
  app.component('SvgIcon', SvgIcon)
}

export function timeFormat(timeStamp, format) {
  let date = new Date(timeStamp)
  let ret
  const opt = {
    'Y+': date.getFullYear().toString(), // 年
    'm+': (date.getMonth() + 1).toString(), // 月
    'd+': date.getDate().toString(), // 日
    'H+': date.getHours().toString(), // 时
    'M+': date.getMinutes().toString(), // 分
    'S+': date.getSeconds().toString() // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  }
  for (let k in opt) {
    ret = new RegExp('(' + k + ')').exec(format)
    if (ret) {
      format = format.replace(ret[1], ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, '0'))
    }
  }
  return format
}

export function isEmptyObj(obj) {
  return Object.getOwnPropertyNames(obj).length === 0
}



export function toThousands(val, precis=0) {
  return val.toFixed(precis).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

const other = {
  elSvg
}

// 统一批量导出
export default other
